<template>

  <transition
    name="fade"
    mode="out-in"
  >
    <div v-if="eventsPageState.events.length">
      <template v-for="(events, key, index) in eventsObj">
        <div
          :key="'t_'+key+index"
          class="mt-4 title is-4 is-uppercase"
        >
          {{createHeader(key)}}
        </div>
        <div
          class="columns columns-events is-multiline is-mobile"
          :key="key"
        >

          <div
            class="column is-half"
            v-for="(event, index) in events"
            :key="index"
          >
            <EventTile :event="event" />
          </div>
        </div>
        <hr
          class="separator"
          v-if="index < (Object.keys(eventsObj).length -1)"
          :key="index"
        />
      </template>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import EventTile from '../events/EventTile.vue'

export default {
  name: 'event-calendar-view',

  components: {
    EventTile
  },

  data() {
    return {
      eventsObj: []
    }
  },

  created() {
  },

  computed: {
    ...mapGetters({
      eventsPageState: 'getEventsPageState'
    })
  },

  watch: {
    eventsPageState: {
      handler: function(newVal, oldVal) {
        if (newVal) {
          this.processEventResponse(newVal.events)
        }
      },
      deep: true
    }
  },

  methods: {
    createHeader(val) {
      let arr = val.split('_')
      return this.$options.filters.getMonthName(arr[0], 'en', true) + ' ' + arr[1]
    },

    /**
     * Process events data
     */
    processEventResponse(response) {
      let events = {}

      for (let i = 0; i < response.length; i++) {
        let date = this.$options.filters.parseIsoDateStringToDate(response[i].StartDate)
        let m = date.getMonth() + 1
        let y = date.getFullYear()
        let keyName = `${m}_${y}`
        if (typeof events[keyName] === 'undefined') {
          events[keyName] = []
        }
        events[keyName].push(response[i])
      }

      this.eventsObj = events
    }
  }
}
</script>